import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'
import Img from "gatsby-image"

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    const { data } = this.props
    return (
      <>
        <Header logo={data.logo.childImageSharp.fluid} front={data.front.childImageSharp.fixed} />
        <Layout>
          <Helmet title="P&R Law Firm Advogados" />

          <Waypoint
            onEnter={this._handleWaypointEnter}
            onLeave={this._handleWaypointLeave}
          ></Waypoint>
          <Nav sticky={this.state.stickyNav} />

          <div id="main">
            <section id="intro" className="main">
              <div className="spotlight">
                <div className="content">
                  <header className="major">
                    <h2>About Us</h2>
                  </header>
                  <p>
                    When you need a good licensed Lawyer to help with an commercial case,
                    a criminal case or a civil case in Timor-Leste,
                  then <strong>P&R</strong> Lawfim and his Team of Timorese and Portuguese Lawyers in Dili Capital
                  are the ones to contact. Since 1985, <strong>P&R</strong> Legal Service staff have been helping Companies,
                  Foreigners and Timorese in Timor-Leste in <span className="highlight">mediation settlements,
                    divorces, civil court, criminal court, at the police stations,
                  at Immigration and in consultations</span> at the <strong>P&R</strong> Legal Services Offices in Dili and Lisbon.
                </p>
                </div>
                <span className="image">
                  <Img fixed={data.justice.childImageSharp.fixed} />
                </span>
              </div>
            </section>

            <section id="first" className="main special">
              <header className="major">
                <h2>Our Services</h2>
              </header>
              <p>
                Our legal services are available in <span className="highlight">English, Portuguese, Tetun ,Bahassa Indonesia and Chinese</span> language
            </p>
              <div className="services">
                <div className="service-item">
                  <Img className="border" fixed={data.handshake.childImageSharp.fixed} />
                  <h3>Commerical</h3>
                  <ul className="dot">
                    <li>Start a Timor-Leste Company, Limited</li>
                    <li>Open or Buy a Business in Timor-Leste</li>
                    <li>Help with any Timor-Leste government permits</li>
                    <li>Settle partnership/company disputes</li>
                    <li>Use our lawyers for commercial cases in Timor-Leste</li>
                    <li>International Contracts</li>
                    <li>Mediation/Conciliations</li>
                    <li>Company Legal Advisers</li>
                  </ul>
                </div>
                <div className="service-item">
                  <Img className="border" fixed={data.handcuff.childImageSharp.fixed} />
                  <h3>Criminal</h3>
                  <ul className="dot">
                    <li>Court Bail</li>
                    <li>Get yourself or others out of jail in Timor-Leste</li>
                    <li>Use our lawyers for criminal cases in Timor-Leste</li>
                  </ul>
                </div>
                <div className="service-item">
                  <Img className="border" fixed={data.passport.childImageSharp.fixed} />
                  <h3>Visa</h3>
                  <ul className="dot">
                    <li>Get a work Visa in Timor-Leste</li>
                    <li>Get a one year Visa in Timor-Leste</li>
                  </ul>
                </div>
                <div className="service-item">
                  <Img className="border" fixed={data.books.childImageSharp.fixed} />
                  <h3>Other</h3>
                  <ul className="dot">
                    <li>Get married in Timor-Leste</li>
                    <li>Divorce in Timor-Leste</li>
                    <li>Retire in Timor-leste</li>
                    <li>Get a Will for Timor-Leste</li>
                    <li>Use our lawyers for civil cases in Timor-Leste</li>
                    <li>Appeal any Government/Court decisions</li>
                    <li>Translations</li>
                  </ul>
                </div>
              </div>
              {/* <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer> */}
            </section>

            <section id="second" className="main special">
              <header className="major">
                <h2>Our Team</h2>
                <p>
                  Timorese international advocates are members of the Portuguese Bar Association (OAP) and Registered as Lawyers in Timor-Leste courts.
                  Our lawyers are real generalists who have gained their experience in national and international business. Besides this broad civil experience each lawyer has his own specialties as well, concerning various areas of law, since 1985 practice in Portugal , Macau and Timor-Leste.
              </p>
              </header>
              <div className="team">
                <Img imgStyle={{ objectFit: "contain", objectPosition: "50% 50%" }} fixed={data.team.childImageSharp.fixed} />
                <div>
                  <header className="lawyers">
                    <h3>Lawyers at P&R LawFirm Service</h3>
                  </header>
                  <ul>
                    <li>
                      <span className="highlight">Lic. J.Andrade</span> - Graduated in Dili , Timor-Leste
                      <p className="subtext">Senior Legal Consultant and University Lecturer</p>
                    </li>
                    <li>
                      <span className="highlight">Lic. Z. Correia</span> – Graduated in Dili, Timor-Leste
                      <p className="subtext">Senior Legal Consultant</p>
                    </li>
                    <li>
                      <span className="highlight">Lic. A. Jesus</span> – Graduated in Dili, Timor-Leste
                      <p className="subtext">Admited to the Bar CSA</p>
                    </li>
                    <li>
                      <span className="highlight">Lic. F. Nicolau</span> - Graduated in Lisbon, Portugal
                      <p className="subtext">Admited to the Bar OAP</p>
                    </li>
                    <li>
                      <span className="highlight">Lic. M. Silva</span> - Graduated in Dili, Timor-Leste
                      <p className="subtext">Admited to the Bar CSA</p>
                    </li>
                    <li>
                      <span className="highlight">Lic. P. Remedios</span> - Graduated in Lisbon, Portugal
                      <p className="subtext">Admited to the Bar OAP</p>
                    </li>
                    <li>
                      <span className="highlight">Lic. A. Xavier</span> – Graduated in Indonesia
                      <p className="subtext">Senior Legal Consultant</p>
                    </li>
                    <li>
                      <div>
                        <span className="highlight">Lic. Xisto Ximenes</span>
                        <p className="subtext">Administrator</p>
                      </div>
                    </li>
                    <li>
                      <div>
                        <span className="highlight">Lic. J.Gama</span>
                        <p className="subtext">Legal Secretary</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section id="fee" className="main special">
              <header className="major">
                <h2>Legal Fees</h2>
                <p>
                  A subscription for fixed legal fee concerning certain ongoing legal services is possible.
                  Our services are particularly fitted for management of all your legal business and private confidential legal issues.
              </p>
              </header>
            </section>
            <section id="cta" className="main special contact">
              <header className="major">
                <h2>Contact us</h2>
              </header>
              <p>
                Registered Offices: <span className="highlight">Estrada Areia Branca, Suco Metiaut, Aldeia 2, Dili, Timor-Leste ( East Timor )</span>
              </p>
              <ul className="details">
                <li>Phone: (+670) 7690 2380</li>
                <li>24hrs: (+670) 7800 1140</li>
                <li>WhatsApp: (+670) 7511 5230</li>
                <li>Email: <a href="mailto:info@pnrlawfirm.com">info@pnrlawfirm.com</a> or <a href="mailto:pnr.lawfirmdili@gmail.com">pnr.lawfirmdili@gmail.com</a>
                </li>
              </ul>
              <header className="major">
                <span className="highlight">Lisbon:</span>
              </header>
              <ul className="details">
                <li>Phone: (+351) 915544995</li>
                <li>Email: <a href="mailto:fnfrancisconicolau@gmail.com">fnfrancisconicolau@gmail.com</a></li>
              </ul>
            </section>
          </div>
        </Layout >
      </>
    )
  }
}

export default Index

export const query = graphql`
  query {
    books: file(relativePath: {eq: "books.jpg"}) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    handcuff: file(relativePath: {eq: "handcuff.jpg"}) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    handshake: file(relativePath: {eq: "handshake.jpg"}) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    passport: file(relativePath: {eq: "passport.jpg"}) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    paulo: file(relativePath: {eq: "paulo2.jpg"}) {
      childImageSharp {
        fixed(width: 280, height: 280) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    unknown: file(relativePath: {eq: "unknown.png"}) {
      childImageSharp {
        fixed(width: 280, height: 280) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    justice: file(relativePath: {eq: "justice.jpg"}) {
      childImageSharp {
        fixed(width: 320, height: 320) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    logo: file(relativePath: {eq: "logo.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    front: file(relativePath: { eq: "front3.jpg" }) {
      childImageSharp {
        fixed(height: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    team: file(relativePath: { eq: "team3.jpg" }) {
      childImageSharp {
        fixed(width: 800) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`