import React from 'react'
import Img from "gatsby-image"

const Header = ({ logo, front }) => (
    <header id="header" className="alt">
        <Img className="front" fixed={front} />
        <span className="logo"><Img fluid={logo} /></span>
        <h1 className="title">P&R Law Firm Advogados</h1>
        <p className="title">Lawyers – Consultants – Advogados - Avocats</p>
    </header>
)

export default Header
